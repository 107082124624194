import React from "react"
import styled from "@emotion/styled"
import { theme, mediaQueries } from "./theme"
import { Container } from "./Container"
import logoFull from "./logo_full.svg"
import facebook from "./facebook.svg"
import { css } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"

const FooterWrapper = styled("footer")`
  padding-top: 60px;
  padding-bottom: 50px;
  background-color: ${theme.colors.blue};
  ${mediaQueries.small(
    css`
      padding-top: 80px;
  padding-bottom: 90px;
    `
  )};
`

const FooterContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1000px;
`

const Logo = styled("img")`
  display: block;
  width: 113px;
  margin-right: 15px;
`

const FacebookLink = styled("a")`
  margin-top: 15px;
  margin-bottom: 15px;
  align-self: center;
`

const Facebook = styled("img")`
  display: block;
`

const Contact = styled("div")`
  padding-top: 19px;
  margin-right: 15px;
  h3 {
    font-weight: 700;
    font-size: ${theme.headings.h3};
    color: ${theme.colors.white};
  }

  p {
    font-size: ${theme.fontSizes.small};
    color: ${theme.colors.white};
    line-height: 1.5714285714;
  }

  a {
    display: block;
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.small};
    text-decoration: none;
    line-height: 1.5714285714;
    &:hover {
      text-decoration: underline;
    }
  }
`

export default () => {
  const data = useStaticQuery(graphql`
    query footerQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "settings" } }) {
        frontmatter {
          contactName
          contactEmail
          contactPhone
        }
      }
    }
  `)

  return (
    <>
      <FooterWrapper id="kontakt">
        <FooterContainer>
          <a href="/">
            <Logo src={logoFull} alt="Logo Education for a changing world" />
          </a>
          <Contact>
            <h3>{data.markdownRemark.frontmatter.contactName}</h3>
            <a href={"mailto:" + data.markdownRemark.frontmatter.contactEmail}>
              {data.markdownRemark.frontmatter.contactEmail}
            </a>
            <a href={"tel:" + data.markdownRemark.frontmatter.contactPhone}>
              {data.markdownRemark.frontmatter.contactPhone}
            </a>
          </Contact>
          <Contact>
            <h3>EDUCON PRAGUE</h3>
            <p>
              <a
                href="https://goo.gl/maps/hximrJPkW8tJkzo76"
                target="_blank"
                rel="noopener noreferrer"
              >
                CETA-Centrum ekonomických a tržních analýz <br />
                Jungmannova 26/15 <br />
                110 00 Praha
              </a>
            </p>
          </Contact>
          <FacebookLink
            href="https://www.facebook.com/eceta.cz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebook src={facebook} alt="Facebook link" />
          </FacebookLink>
        </FooterContainer>
      </FooterWrapper>
    </>
  )
}

import { PageRendererProps } from "gatsby"
import React, { ReactNode } from "react"
import Helmet from "react-helmet"
import { GlobalStyles } from './GlobalStyles'
import { Header } from '../components/Header'
import Footer from '../components/Footer'

interface Props {
  title: string
  description: string
  children: ReactNode
}

const Layout = (props: Props) => {
  const { children, title, description } = props

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content="https://www.educonprague.com/educon-og-facebook.png"
        />
        <meta property="og:url" content="/" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://www.educonprague.com/educon-og-twitter.png"
        />
        <meta name="twitter:image:alt" content={description} />
        <meta name="twitter:site" content="@e_ceta" />
        <meta name="twitter:creator" content="@e_ceta" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#261d5d" />
        <meta name="theme-color" content="#261d5d" />
      </Helmet>
      <GlobalStyles />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout

import { css } from "@emotion/core"
import React from "react"
import { mediaQueries } from "./theme"

interface Props {
  children: any
  id?: string
  className?: string
  line?: boolean
}

const lineStyles = css`
  position: relative;
  z-index: 0;
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #eeeeee;
  }
`

export const Container = (props: Props) => {
  const containerStyles = [
    css`
      max-width: 1440px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 16px;
      padding-right: 16px;
    `,
    mediaQueries.small(css`
      padding-left: 30px;
      padding-right: 30px;
    `),
  ]

  return (
    <div id={props.id} className={props.className} css={[containerStyles, props.line && lineStyles]}>{props.children}</div>
  )
}

import { Global, css } from '@emotion/core'
import React from "react"
import { theme } from "./theme"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: "GT Pressura";
          src: local('GT Pressura Bold'), local('GTPressura-BoldItalic'), url(../fonts/GTPressura-BoldItalic.woff2) format('woff2'), url("./fonts/GTPressura-BoldItalic.woff") format("woff");
          font-weight: bold;
          font-style: italic;
          font-display: swap;
        }

        @font-face {
          font-family: "GT Pressura";
          src: local('GT Pressura Light'), local('GTPressura-Light'), url(../fonts/GTPressura-Light.woff2) format('woff2'),
            url("./fonts/GTPressura-Light.woff") format("woff");
          font-weight: 300;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: "GT Pressura";
          src: local('GT Pressura Bold'), local('GTPressura-Bold'), url(../fonts/GTPressura-Bold.woff2) format('woff2'),
            url("./fonts/GTPressura-Bold.woff") format("woff");
          font-weight: bold;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: "GT Pressura";
          src: local('GT Pressura'), local('GTPressura'), url(../fonts/GTPressura.woff2) format('woff2'),
            url("./fonts/GTPressura.woff") format("woff");
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }

        @font-face {
          font-family: "GT Pressura";
          src: local('GT Pressura Light'), local('GTPressura-LightItalic'), url(../fonts/GTPressura-LightItalic.woff2) format('woff2'),
            url("./fonts/GTPressura-LightItalic.woff") format("woff");
          font-weight: 300;
          font-style: italic;
          font-display: swap;
        }

        @font-face {
          font-family: "GT Pressura";
          src: local('GT Pressura'), local('GTPressura-Italic'), url(../fonts/GTPressura-RegularItalic.woff2) format('woff2'),
            url("./fonts/GTPressura-RegularItalic.woff") format("woff");
          font-weight: normal;
          font-style: italic;
          font-display: swap;
        }


        html {
          box-sizing: border-box;
          font-size: 16px;
        }

        body {
          font-family: "GT Pressura", sans-serif;
          font-weight: 400;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          line-height: 1.5;
          background-color: ${theme.colors.blue};
        }

        button {
          font-family: "GT Pressura", sans-serif;
        }

        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }

        body,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        ol,
        ul {
          margin: 0;
          padding: 0;
          font-weight: normal;
        }

        ol,
        ul {
          list-style: none;
        }

        img {
          max-width: 100%;
          height: auto;
        }

        .ReactModal__Body--open {
          overflow: hidden;
          .slick-slider {
            pointer-events: none;
          }
        }
      `}
    />
  )
}

import React from "react"
import { css } from "@emotion/core"
import { theme } from "./theme"

interface Props {
  open: boolean
  className?: string
}

export const Hamburger = (props: Props) => {
  const hambuderStyles = css`
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: ${theme.colors.white};
      opacity: 1;
      right: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
    }

    span:nth-of-type(1) {
      top: 2px;
    }

    span:nth-of-type(2) {
      top: 9px;
    }

    span:nth-of-type(3) {
      top: 16px;
    }
  `

  const openStyles = css`
    span:nth-of-type(1) {
      top: 9px;
      transform: rotate(135deg);
    }

    span:nth-of-type(2) {
      opacity: 0;
      right: -20px;
    }

    span:nth-of-type(3) {
      top: 9px;
      transform: rotate(-135deg);
    }
  `

  return (
    <div css={[hambuderStyles, props.open && openStyles]} className={props.className}>
      <span />
      <span />
      <span />
    </div>
  )
}

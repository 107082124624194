import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Hamburger } from "./Hamburger"
import { Container } from "./Container"
import { theme, mediaQueries } from "./theme"
import { MenuLink, menuLinkStyles } from "./MenuLink"
import logo from "./logo.svg"
import { Link } from "gatsby"

const Navbar = styled("header")`
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: ${theme.colors.blue};
`

const NavbarContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`

const LargeNavbarLinks = styled("ul")`
  display: none;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  ${mediaQueries.medium(
    css`
      display: flex;
    `
  )}

  li {
    align-self: center;
  }

  li:not(:first-of-type) {
    margin-left: 40px;
    ${mediaQueries.large(
      css`
        margin-left: 60px;
      `
    )}
  }
`

const SmallNavbarMenu = styled("div")`
  display: flex;
  text-align: right;
  flex-direction: column;
  -webkit-tap-highlight-color: transparent;
  ${mediaQueries.medium(
    css`
      display: none;
    `
  )};
`

const SmallNavbarButton = styled("div")`
  color: ${theme.colors.gray};
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const SmallNavbarButtonText = styled("div")`
  display: inline-block;
  margin-right: 10px;

  ${menuLinkStyles};
`

const SmallNavbarLinks = styled("ul")`
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 5px 30px 0 0;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  max-height: 0;
  overflow: hidden;

  li {
    padding: 3px 0;
    align-self: center;
  }

  &.visible {
    opacity: 1;
    max-height: 300px;
  }
`

const Logo = styled("img")`
  display: block;
`

interface State {
  menuShown: boolean
}

export class Header extends React.PureComponent<any, State> {
  state = { menuShown: false }

  render() {
    return (
      <Navbar>
        <NavbarContainer>
          <Link to="/">
            <Logo src={logo} alt="Logo Educon Prague" />
          </Link>
          <LargeNavbarLinks>{this.renderItems()}</LargeNavbarLinks>
          <SmallNavbarMenu>
            <SmallNavbarButton onClick={this.toggleMenu}>
              <SmallNavbarButtonText>
                {this.state.menuShown ? "Zavřít" : "Menu"}
              </SmallNavbarButtonText>
              <Hamburger open={this.state.menuShown} />
            </SmallNavbarButton>
            <SmallNavbarLinks className={this.state.menuShown ? "visible" : ""}>
              {this.renderItems()}
            </SmallNavbarLinks>
          </SmallNavbarMenu>
        </NavbarContainer>
      </Navbar>
    )
  }

  private renderItems() {
    return (
      <>
        <li>
          <MenuLink to="/#o-nas">O nás</MenuLink>
        </li>
        <li>
          <MenuLink to="/#kalendar-akci">Kalendář akcí</MenuLink>
        </li>
        <li>
          <MenuLink to="/#zajima-nas">Zajímá nás</MenuLink>
        </li>
        <li>
          <MenuLink to="/#probehle-akce">Proběhlé akce</MenuLink>
        </li>
        <li>
          <MenuLink to="/#partnerstvi">Partnerství</MenuLink>
        </li>
        <li>
          <MenuLink to="/#kontakt">Kontakt</MenuLink>
        </li>
      </>
    )
  }

  private toggleMenu = () => {
    this.setState({ menuShown: !this.state.menuShown })
  }
}

import { css } from "@emotion/core"

const baseSize = 16

export const theme = {
  colors: {
    blue: "#261d5d",
    blueDarken: "#1e174a",
    white: "#ffffff",
    gray: "#676767",
    grayLight: "#faf9f9",
  },
  fontSizes: {
    large: "24px",
    base: baseSize + "px", // 16px
    small: "14px",
  },
  paddings: {
  },
  headings: {
    h1: baseSize * 5 + "px",      // 80px
    h2: baseSize * 1.5625 + "px", // 25px
    h3: baseSize * 1.125 + "px", // 18px
  },
  headingsMobile: {
    h1: baseSize * 3.125 + "px",      // 80px
    h2: baseSize * 1.5625 + "px", // 25px
  },
  breakPoints: {
    base: 0,
    small: 576,
    medium: 768,
    large: 992,
    extraLarge: 1200
  }
}

const andMaxWidth = (max?: number) => (max ? ` and (max-width: ${max}px)` : "")

export const mediaQueries = {
  base: (innerCss: any, max?: number) =>
    css`
      @media (min-width: 0px) ${andMaxWidth(max)} {
        ${innerCss};
      }
    `,
  small: (innerCss: any, max?: number) =>
    css`
      @media (min-width: 576px) ${andMaxWidth(max)} {
        ${innerCss};
      }
    `,
  medium: (innerCss: any, max?: number) =>
    css`
      @media (min-width: 768px) ${andMaxWidth(max)} {
        ${innerCss};
      }
    `,
  large: (innerCss: any, max?: number) =>
    css`
      @media (min-width: 992px) ${andMaxWidth(max)} {
        ${innerCss};
      }
    `,
  extraLarge: (innerCss: any) =>
    css`
      @media (min-width: 1200px) {
        ${innerCss};
      }
    `
}

import React from "react"
import { theme, mediaQueries} from "./theme"
import { css } from "@emotion/core"
import { Link } from "gatsby"

export const menuLinkStyles = css`
  position: relative;
  color: ${theme.colors.white};
  text-decoration: none;
  font-size: ${theme.fontSizes.small};
  &::before {
    ${mediaQueries.medium(
      css`
        pointer-events: none;
        content: "";
        position: absolute;
        bottom: -8px;
        left: 0;
        right: 0;
        height: 3px;
        background-color: transparent;
        transition: background-color 0.2s ease-in-out, bottom 0.2s ease-in-out, height 0.2s ease-in-out;
      `
    )};
  }

  &:hover {
    &::before {
      ${mediaQueries.medium(
        css`
          height: 2px;
          bottom: -5px;
          background-color: ${theme.colors.white};
        `
      )};
    }
  }
`

interface Props {
  children: React.ReactNode
  to: string
}

export const MenuLink = (props: Props) => (
  <Link css={menuLinkStyles} to={props.to}>
    {props.children}
  </Link>
)
